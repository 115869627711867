import logo from './DumpsterFire.jpg';
import './App.css';

function App() {
  return (
    <div class="p-10 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4" id='root'>
      <div class="shrink-1">
        <img class="h-32 w-32" src={logo} alt="dumpster" />
        <h1 className="text-6xl font-bold">
          Everything's just fine.
        </h1>
      </div>
    </div>
  );
}

export default App;
